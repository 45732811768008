@import "https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap";
.country-not-found {
  color: red;
  text-align: center;
  margin-top: 2rem;
  font-size: 2rem;
  font-weight: 700;
}

body {
  background-color: var(--lightBg);
  color: var(--lightText);
}

.loading-container.dark-mode, header.dark-mode, main.dark-mode {
  --lightComponents: #2b3743;
  --lightBg: #202c37;
  --lightText: white;
  --darkText: #cacaca;
  box-shadow: none !important;
}

main.dark-mode .search-container, main.dark-mode .select-reg-btn {
  box-shadow: none !important;
}

* {
  box-sizing: border-box;
  font-optical-sizing: auto;
  font-variation-settings: "wdth" 100, "YTLC" 500;
  margin: 0;
  padding: 0;
  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
}

.set-layout {
  padding: 1rem 3rem;
}

header {
  background-color: var(--lightComponents);
  z-index: 1;
  color: var(--lightText);
  padding: 1rem 3rem;
  position: sticky;
  top: 0;
  left: 0;
  box-shadow: 0 2px 7px #00000059;
}

.nav-bar {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.title-nav {
  font-size: 1.5rem;
  font-weight: 800;
}

.theme-icon {
  color: var(--lightText);
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  display: inline-flex;
}

#themeBtn {
  cursor: pointer;
  color: var(--lightText);
  background: none;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  padding: .3rem;
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
}

.theme-container {
  justify-content: center;
  align-items: center;
  gap: .4rem;
  display: flex;
}

.main-section {
  background: var(--lightBg);
  color: var(--darkText);
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 3rem;
  min-height: 100vh;
  padding-top: 3rem;
  display: flex;
}

.feature-container {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.search-container {
  border-radius: 8px;
  min-width: 45%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 7px 29px #64646f33;
}

.search-icon {
  color: var(--lightText);
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 4%;
  transform: translateY(-50%);
}

#search-input {
  background: var(--lightComponents);
  color: var(--lightText);
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 1rem 0 1rem 3.8rem;
  font-size: 1.2rem;
}

.filter-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  display: flex;
  position: relative;
}

.select-reg-btn {
  cursor: pointer;
  background-color: var(--lightComponents);
  border-radius: 7px;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  display: flex;
  box-shadow: 0 8px 24px #959da533;
}

.list-region {
  z-index: 1;
  background-color: var(--lightComponents);
  border-radius: 7px;
  width: 100%;
  height: 0;
  transition: height .1s ease-in;
  position: absolute;
  top: 115%;
  overflow: hidden;
}

.list-region > ul > li {
  text-align: center;
  cursor: pointer;
  padding: .4rem 0;
  list-style: none;
}

.list-region > ul > li:hover {
  background-color: #95959533;
}

.active-list-region {
  height: 172px;
}

.country-items {
  grid-template-columns: repeat(auto-fill, 280px);
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  width: 100%;
  height: auto;
  display: grid;
}

.country-items > a {
  color: var(--lightText);
  min-width: 280px;
  text-decoration: none;
  list-style: none;
  transition: all .1s ease-in;
  box-shadow: 0 3px 8px #0000003d;
}

.country-items > a:hover {
  transform: scale(1.05);
}

.cntry-item {
  background-color: var(--lightComponents);
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  padding: 0 0 2rem;
  overflow: hidden;
}

.cntry-item > a {
  list-style: none;
}

.cntry-flag {
  object-fit: cover;
  width: 100%;
  height: 200px;
}

.cntry-name {
  color: var(--lightText);
  margin-top: 1rem;
  padding-left: 1rem;
  font-size: 1.5rem;
  font-weight: 800;
}

.cntry-details {
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: .2rem;
  margin-top: 1rem;
  padding-left: 1rem;
  display: flex;
}

.txtColorBg {
  color: var(--darkText);
}

.cntry-popul, .cntry-region, .cntry-capital {
  color: var(--lightText);
  font-weight: 700;
}

.font-bold {
  font-weight: 700;
}

.country-name {
  font-size: 2rem;
  font-weight: 800;
}

.country-container {
  justify-content: space-between;
  align-items: center;
  gap: 10rem;
  width: 100%;
  display: flex;
}

.main-country-details {
  width: 100%;
}

.details-country {
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 2rem;
  margin-top: 2rem;
  display: flex;
}

.country-container > .main-country-img {
  width: 542px;
  height: 344px;
}

.cntry-main-details {
  grid-template-columns: 1fr 1fr;
  gap: .5rem 1rem;
  width: 100%;
  display: grid;
}

.border-country > p {
  font-weight: 800;
}

.border-cntry-btn {
  cursor: pointer;
  color: #5c5c5c;
  text-align: center;
  border: 1px solid #959595;
  border-radius: 3px;
  width: 125px;
  padding: .4rem;
  font-size: 1rem;
  font-weight: 600;
}

.border-country {
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  width: 100%;
  display: flex;
}

.font-bold:nth-child(8) {
  grid-row-start: 5;
}

.back-btn {
  background-color: var(--lightComponents);
  cursor: pointer;
  color: var(--lightText);
  border: none;
  border-radius: 7px;
  outline: none;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 170px;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  box-shadow: 0 3px 8px #0000003d;
}

.border-btn-container {
  flex-flow: wrap;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.loading-container {
  background-color: var(--lightBg);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: .2rem;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle-loading {
  border: 7px solid #75b34c;
  border-bottom-color: #0000;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  animation: 1s linear infinite forwards loading;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

:root {
  --lightBg: #fafafa;
  --lightText: black;
  --darkBg: #202c37;
  --darkText: black;
  --lightComponents: white;
  --darkComponents: #2b3743;
}

@media only screen and (width <= 900px) {
  .country-container {
    flex-direction: column;
    justify-content: space-between;
    gap: 10rem;
    width: 100%;
    display: flex;
  }
}

@media only screen and (width <= 600px) {
  .set-layout {
    padding: 1rem;
  }

  .title-nav {
    font-size: 1rem;
    font-weight: 800;
  }

  #themeBtn {
    font-size: 1rem;
  }

  .country-items {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    display: flex;
  }

  .cntry-item {
    background-color: var(--lightComponents);
    cursor: pointer;
    border-radius: 10px;
    width: auto;
    min-width: 90%;
    padding: 0 0 2rem;
    overflow: hidden;
    box-shadow: 0 5px 15px #00000059;
  }

  .country-items > a {
    color: #000;
    justify-content: center;
    align-items: center;
    max-width: 82%;
    text-decoration: none;
    list-style: none;
    display: flex;
  }

  .feature-container {
    flex-direction: column;
    justify-content: center;
    align-items: normal;
    gap: 1rem;
    width: 100%;
    display: flex;
  }

  .filter-container {
    max-width: 55%;
  }

  .select-reg-btn {
    cursor: pointer;
    padding: 1.5rem;
    display: flex;
  }

  #search-input {
    padding: 1.5rem 0 1.5rem 3.8rem;
  }

  .cntry-flag {
    width: 100%;
    height: auto;
  }

  .country-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10rem;
    width: 100%;
    display: flex;
  }

  .cntry-main-details {
    grid-template-columns: auto;
    gap: .5rem 1rem;
    width: 100%;
    display: grid;
  }
}

@media only screen and (width <= 450px) {
  .set-layout {
    padding: 1rem;
  }

  .country-items {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    display: flex;
  }

  .cntry-item {
    cursor: pointer;
    border-radius: 10px;
    width: auto;
    min-width: 90%;
    padding: 0 0 2rem;
    overflow: hidden;
    box-shadow: 0 5px 15px #00000059;
  }

  .country-items > a {
    color: #000;
    justify-content: center;
    align-items: center;
    max-width: 82%;
    text-decoration: none;
    list-style: none;
    display: flex;
  }

  .feature-container {
    flex-direction: column;
    justify-content: center;
    align-items: normal;
    gap: 1rem;
    width: 100%;
    display: flex;
  }

  .filter-container {
    max-width: 62%;
  }

  .select-reg-btn {
    cursor: pointer;
    padding: 1.3rem;
    display: flex;
  }

  #search-input {
    padding: 1.5rem 0 1.5rem 3.8rem;
  }

  .cntry-flag {
    width: 100%;
    height: auto;
  }

  .country-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    width: 100%;
    display: flex;
  }

  .cntry-main-details {
    grid-template-columns: auto;
    gap: .5rem 1rem;
    width: 100%;
    display: grid;
  }

  .country-container > .main-country-img {
    width: 337px;
    height: 246px;
  }

  .border-country {
    flex-flow: column wrap;
    justify-content: start;
    align-items: start;
    gap: 1rem;
    width: 100%;
    display: flex;
  }

  .border-cntry-btn {
    cursor: pointer;
    color: #5c5c5c;
    text-align: center;
    border: 1px solid #959595;
    border-radius: 3px;
    width: 108px;
    padding: .2rem;
    font-size: .8rem;
    font-weight: 600;
  }

  .border-btn-container {
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    gap: .5rem;
    display: flex;
  }
}
/*# sourceMappingURL=index.8865eeb1.css.map */
